import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../../hooks/storeHooks';
import {
  updateAssetsDisplayState,
  fetchInitialStateAsync,
  updateSelectedAsset,
  updateSearchGroups,
  Group,
} from '../AssetGroupsSlice';
import { fetchNotificationsAsync } from '../NotificationsSlice';
import { ReactComponent as SearchIcon } from '../../../images/search-md.svg';
import TrackingDecorator from '../../common/Logging/TrackingDecorator';
import { useNavigate } from 'react-router-dom';
import { setIndustryApplicationId } from '../../asset-analysis/AssetAnalysisSlice';
import NavigationIcons from './NavigationIcons';
import GroupListItemCollapse from '../icons/GroupListItemCollapse';
import GroupListItemExpand from '../icons/GroupListItemExpand';
import GroupIcon from '../icons/GroupIcon';
import { fetchDataHistoryTrendLibraryAsync } from './../../data-history/TrendLibrary/TrendLibrarySlice';
import Loader from '../../common/Loader/Loader';

interface TrackingProps {
  onClick: () => void;
  tracking: {
    capture: (eventName: string, eventData: object) => void;
  };
}

const NavigationDrawerItem: React.FC<TrackingProps> = () => {
  const dispatch = useAppDispatch();
  const assetGroups = useAppSelector((state) => state.assetGroups?.searchGroups);
  const selectedAssetId = useAppSelector((state) => state.assetGroups?.selectedAssetId);
  const selectedGroupAssetId = useAppSelector((state) => state.assetGroups?.selectedGroup?.assetGroupName);
  const assets = useAppSelector((state) => state.assetGroups?.assets);
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState<string>('');
  const [alreadyOpen, setAlreadyOpen] = useState(false);

  const handleAssetGroupClick = (id: number) => {
    if (!alreadyOpen) {
      setAlreadyOpen(true);
    }
    dispatch(
      updateAssetsDisplayState({
        groupId: id,
        assetId: undefined,
        searchString: searchString,
        assetIdGuid: undefined,
      }),
    );
  };

  useEffect(() => {
    const elements = document.querySelectorAll('.navigation-content-frame-item');

    if (elements.length === 1 && !alreadyOpen) {
      if (assetGroups && assetGroups?.length > 0) {
        const id = assetGroups[0].id || 1;
        handleAssetGroupClick(id);
      }
    }
    return () => {
      setAlreadyOpen(false);
    };
  }, [assetGroups]);

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (assetGroups && assetGroups?.length > 0) {
        const assetId = assetGroups[0].assets[0].assetName;
        const assetIdGuid = assetGroups[0].assets[0].assetId;

        dispatch(
          updateSelectedAsset({
            groupId: assetGroups[0].id,
            assetId: assetId,
            searchString: searchString,
            assetIdGuid: assetIdGuid,
          }),
        );
      }
    }
  };

  useEffect(() => {
    // tracking.capture('Assets', 'Page Loaded');
    if (!assetGroups || !assetGroups.length) {
      dispatch(fetchInitialStateAsync());
    }
  }, [dispatch]);

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    setSearchString(searchValue);
    dispatch(
      updateSearchGroups({
        groupId: undefined,
        assetId: undefined,
        searchString: searchValue,
        assetIdGuid: undefined,
      }),
    );
  };

  const handleAssetIdClick = (
    assetName: string | undefined,
    groupId: number,
    assetId: string | undefined,
    industryApplicationId: number,
    groupName: string | undefined,
  ) => {
    dispatch(
      updateSelectedAsset({ groupId: groupId, assetId: assetName, searchString: undefined, assetIdGuid: assetId }),
    );
    dispatch(
      fetchNotificationsAsync({
        type: 'asset',
        id: assetId || '',
        notificationTypeId: '',
      }),
    );

    dispatch(setIndustryApplicationId({ industryApplicationId: industryApplicationId }));

    if (assetName) {
      let currentTab = location.pathname.split('/')[2]; // status or analysis
      currentTab = currentTab == undefined ? 'status' : currentTab;
      if (groupName) {
        const formattedGroupName = groupName.split(' ').join('_');
        navigate(
          `/asset/` +
            currentTab +
            `/${assets?.find((asset) => asset.assetName === assetName)?.assetId}/${encodeURIComponent(
              formattedGroupName,
            )}`,
        );
      }
    }
    dispatch(fetchDataHistoryTrendLibraryAsync({ assetId: assetId, groupName: groupName }));
  };

  const handleAssetGroupNameClick = (groupId: number, groupName: string | undefined) => {
    dispatch(
      updateSelectedAsset({
        groupId: groupId,
        assetId: undefined,
        searchString: undefined,
        assetIdGuid: undefined,
      }),
    );
    dispatch(fetchNotificationsAsync({ type: 'group', id: groupName }));
    navigate(`/group/status/${encodeURIComponent(groupName ?? '')}`);
  };

  const renderGroup = (group: Group, index: number, indentLevel = 0) => {
    const isGroupActive = selectedAssetId ? false : selectedGroupAssetId === group.assetGroupName;

    return (
      <div key={group.assetGroupName + index.toString()} className='flex flex-col gap-1'>
        <div className={`${isGroupActive ? group.class + ' item-selected' : group.class}`}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '8px',
              width: '100%',
              alignItems: 'center',
              paddingLeft: `${indentLevel * 12}px`,
            }}
            onClick={() => (group.assets.length > 0 ? handleAssetGroupNameClick(group.id, group.assetGroupName) : null)}
          >
            <GroupIcon color={isGroupActive ? '#60BFDA' : '#B8C5CC'} useAssetsIcon={group.assets.length > 0} />
            <div className='navigation-content-frame-item-content'>
              <div
                data-testid={`navbar-${group.assetGroupName}`}
                className={`navigation-content-frame-item-text ${isGroupActive ? 'text-active' : ''}`}
              >
                {group.assetGroupName}
              </div>
            </div>
            <div className={`navigation-content-frame-item-count ${isGroupActive ? 'active' : ''}`}>
              {(group.childGroups?.length ?? 0) + (group.assets?.length ?? 0)}
            </div>
          </div>
          <div onClick={() => handleAssetGroupClick(group.id)}>
            {group.showAssets ? (
              <GroupListItemCollapse isActive={isGroupActive} />
            ) : (
              <GroupListItemExpand isActive={isGroupActive} />
            )}
          </div>
        </div>
        {group.showAssets && (
          <div>
            <div className='flex flex-col'>
              {group.childGroups?.map((childGroup, childIndex) =>
                renderGroup(childGroup, index + childIndex + 1, indentLevel + 1),
              )}
              {group.assets?.map((asset, assetIndex) => {
                const isAssetActive = selectedAssetId === asset.assetName;

                return (
                  <div
                    data-testid={`navbar-${asset.assetName}`}
                    key={asset.assetName + assetIndex.toString()}
                    className={`${
                      isAssetActive ? 'navigation-content-frame-asset item-selected' : 'navigation-content-frame-asset'
                    }`}
                    onClick={() => {
                      handleAssetIdClick(
                        asset.assetName,
                        group.id,
                        asset.assetId,
                        asset.industryApplicationId,
                        group.assetGroupName,
                      );
                    }}
                  >
                    <div
                      className={`navigation-content-frame-item-text ${isAssetActive ? 'text-active' : ''}`}
                      style={{
                        paddingLeft: `${(indentLevel + 1) * 12}px`,
                      }}
                    >
                      <div
                        className={
                          asset.enabled
                            ? 'navigation-content-well-enable-status enabled'
                            : 'navigation-content-well-enable-status disabled'
                        }
                      />
                      <NavigationIcons asset={asset} isActive={isAssetActive} />
                      {asset.assetName}
                    </div>
                    {asset.wellOpType == 3 && <div className='navigation-content-variable-speed-pill' />}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  };
  const assetGroupDetails = assetGroups?.map((value: Group, index: number) => renderGroup(value, index));
  return (
    <div data-testid='asset-navigation-drawer' className='navigation-bar-items'>
      <div className='navigation-bar-items-content'>
        <div className='navigation-content-headline'>Groups and Assets</div>
        <div className='navigation-content-search-and-filter'>
          <div className='navigation-content-search'>
            <SearchIcon className='search-icon' width='20' height='20' stroke='#647980' />
            <input
              className='input-with-icon'
              placeholder='Search'
              onChange={handleSearchInputChange}
              onKeyDown={handleKeyPress}
            />
          </div>
        </div>
        {assetGroups == null && <Loader />}
        <div className='navigation-content-frame'>{assetGroupDetails}</div>
      </div>
    </div>
  );
};

const TrackedNavigationDrawerItem = TrackingDecorator(NavigationDrawerItem);
export default TrackedNavigationDrawerItem;
